<template>
	<div class="row offers mt-2">
		<b-col cols="12" class="mt-4">
			<router-link class="btn btn-sm btn-default" :to="{name: path_tiercode}">
				<em class="fa fa-backward"></em> Manage Tiercode
			</router-link>
		</b-col>
		<b-col lg="12" class="mt-4">
			<div class="card">
				<b-row align-h="center">
					<b-col lg="6">
						<div class="p-2">
							<div class="mt-2 text-center">Search Tiercode</div>
							<b-input-group class="mt-3">
								<b-form-input
									v-model="query_search"
									type="search"
									placeholder="Type to search"
									@change="setQuerySearch"
									trim
								></b-form-input>
								<b-input-group-append>
									<b-button type="submit" variant="primary" @click="asyncFind">Search</b-button>
									<b-button type="button" variant="dark" class="ml-2" @click="asyncClearFind">Clear</b-button>
									<b-spinner v-show="isLoadingSearch" variant="dark" class="ml-2" label="Loading .."></b-spinner>
								</b-input-group-append>
							</b-input-group>
						</div>
					</b-col>
				</b-row>
				<div class="card-body">
					<div class="row justify-content-end pb-1">
						<span>
							<button class="btn btn-sm btn-dark" @click="actionGetInvalidTiercodeOffers">
								<em class="fa fa-refresh"></em>
							</button>
						</span>
						<label for="" class="col-sm-auto">Count </label>
						<strong class="col-sm-auto">{{ totalRows }}</strong>
					</div>
					<b-table
						sticky-header="500px"
						:items="items"
						:fields="fields"
						:busy="isLoading"
						:isLoading="isLoading"
						show-empty
						responsive="xl"
						class="mt-2"
						hover
					>
						<template v-slot:table-busy>
							<div class="text-center text-dark my-2">
								<b-spinner
									class="align-middle"
									variant="dark"
									type="grow"
									label="Loading .."
								>
								</b-spinner>
								Loading ..
							</div>
						</template>
						<template #cell(No)="row">
							{{ (currentPage - 1) * perPage + (row.index + 1) }}
						</template>
						<template #cell(tier_code)="row">
							{{ row.item.tier_code }}
						</template>
						<template #cell(current_price_idr)="row">
							{{ formatPrice(row.item.current_price_idr, "IDR") }}
						</template>
						<template #cell(price_idr)="row">
							{{ formatPrice(row.item.price_idr, "IDR") }}
						</template>
						<template #cell(tier_price)="row">
							{{ formatPrice(row.item.tier_price, "USD") }}
						</template>
						<template #cell(platform)="row">
							{{ row.item.platform }}
						</template>
						<template #cell(is_active)="row">
							<div>
								<span v-if="row.item.is_active" class="badge badge-success">
									Active
								</span>
								<span v-else class="badge badge-danger">
									Inactive
								</span>
							</div>
						</template>
						<template #cell(created)="row">
							{{ formatDate(row.item.created) }}
						</template>
						<template #cell(action_edit)="row">
							<div class="text-right">
								<router-link class="btn btn-sm btn-default" :to="{name: generatePathNameEditOffer(row.item.offer_type_id), params: { id: row.item.id }}" target="_blank">
									<em class="fa fa-edit"></em>
								</router-link>
							</div>
						</template>
					</b-table>
				</div>
				<b-row v-if="(totalRows/perPage) > 1" class="justify-content-start ml-1 mt-2 pt-4">
					<b-col lg="3">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							aria-controls="my-table"
						/>
					</b-col>
				</b-row>
			</div>
		</b-col>
	</div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import {
	IosTierManagePath,
	AndroidTierManagePath,
	SingleEditPath,
	BundleEditPath,
	SubscriptionEditPath,
	PremiumEditPath,
} from "../../router/marketing";
import constant from "../../store/constant";
export default {
	name: "manage-invalid-tiercode-offers",
	data() {
		return {
			currentPage: 1,
			perPage: 100,
			platform_id: null,
			path_tiercode: null,
			query_search: '',
			isLoadingSearch: false,
			fields: [
				{ key: "No", label: "No" },
				{
					key: "name",
					label: "Offer Name",
				},
				{ key: "tier_code", label: "Offer Tier Code" },
				{
					key: "current_price_idr",
					label: "Offer Price IDR",
					thClass: "text-right",
					tdClass: "text-right",
				},
				{
					key: "price_idr",
					label: "Tiercode Price IDR",
					thClass: "text-right",
					tdClass: "text-right",
				},
				{
					key: "tier_price",
					label: "Tiercode Price",
					thClass: "text-right",
					tdClass: "text-right",
				},
				{ key: "platform", label: "Platform" },
				{ key: "is_active", label: "Active" },
				{
					key: "created",
					label: "Created",
					formatter: function(value) {
						return moment(value).format("DD-MM-YYYY");
					},
				},
				{ key: "action_edit", label: "Edit" },
			],
		};
	},
	computed: {
		...mapState({
			isLoading: (state) => state.invalidTiercodeOffer.isLoading,
			items: (state) => state.invalidTiercodeOffer.items,
			isError: (state) => state.invalidTiercodeOffer.isError,
			totalRows: (state) => state.invalidTiercodeOffer.totalRows,
		}),
	},
	mounted() {
		this.query_search = this.$route.params.tiercode || "";
		this.platform_id = this.$route.params.platform;
		this.path_tiercode = this.manageTiercodePathName(this.platform_id);
		this.actionGetInvalidTiercodeOffers();
	},
	watch: {
		currentPage: function() {
			this.actionGetInvalidTiercodeOffers();
		},
	},
	methods: {
		...mapActions("invalidTiercodeOffer", ["fetchInvalidTiercodeOffer", "searchInvalidTiercodeOffer"]),
		actionGetInvalidTiercodeOffers() {
			if (this.query_search == "") {
				let payload = {
					page: this.currentPage,
					limit: this.perPage,
					platform_id: this.platform_id,
				};
				this.fetchInvalidTiercodeOffer(payload);
			} else {
				this.asyncFind();
			}
		},
		setQuerySearch(query) {
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				this.query_search = query;
			}, 1000);
		},
		formatDate(tgl) {
			if (tgl) {
				return moment(tgl).format("DD MMM YY hh:mm");
			}
			return "-";
		},
		asyncFind() {
			this.isLoadingSearch = true;
			clearTimeout(this.debounce);
			this.debounce = setTimeout(() => {
				const query = this.query_search;
				const platform_id = this.platform_id;
				if (!query) {
					this.actionGetInvalidTiercodeOffers();
					this.isLoadingSearch = false;
					return;
				}
				this.searchInvalidTiercodeOffer({
					q: query,
					platform_id: platform_id,
					page: this.currentPage,
					limit: this.perPage,
				})
					.then((response) => {
						this.items = response.data.data.rows;
						this.isLoadingSearch = false;
					})
					.catch(() => {
						this.isLoadingSearch = false;
					});
			}, 1000);
		},
		asyncClearFind() {
			this.query_search = "";
			this.actionGetInvalidTiercodeOffers();
			this.isLoadingSearch = false;
		},
		formatPrice(value, currency) {
			if (value) {
				let formatter = null;
				if (currency == "USD") {
					formatter = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: currency,
					});
				} else {
					formatter = new Intl.NumberFormat("en-ID", {
						style: "currency",
						currency: currency,
					});
				}

				return formatter.format(value);
			}

			return "0";
		},
		manageTiercodePathName(platformId) {
			if (platformId == constant.PLATFORM.IOS) {
				return IosTierManagePath.name;
			}
			if (platformId == constant.PLATFORM.ANDROID) {
				return AndroidTierManagePath.name;
			}
			return "";
		},
		generatePathNameEditOffer(offerType) {
			if (offerType == constant.OFFER_TYPE.SINGLE) {
				return SingleEditPath.name;
			}
			if (offerType == constant.OFFER_TYPE.BUNDLE) {
				return BundleEditPath.name;
			}
			if (offerType == constant.OFFER_TYPE.SUBSCRIPTIONS) {
				return SubscriptionEditPath.name;
			}
			if (offerType == constant.OFFER_TYPE.BUFFET) {
				return PremiumEditPath.name;
			}
		},
	},
};
</script>
